import request from '@/utils/request'


// 查询用户注销申请列表
export function listCancel(query) {
  return request({
    url: '/user/cancel/list',
    method: 'get',
    params: query
  })
}

// 查询用户注销申请分页
export function pageCancel(query) {
  return request({
    url: '/user/cancel/page',
    method: 'get',
    params: query
  })
}

// 查询用户注销申请详细
export function getCancel(data) {
  return request({
    url: '/user/cancel/detail',
    method: 'get',
    params: data
  })
}

// 新增用户注销申请
export function addCancel(data) {
  return request({
    url: '/user/cancel/add',
    method: 'post',
    data: data
  })
}

// 修改用户注销申请
export function updateCancel(data) {
  return request({
    url: '/user/cancel/edit',
    method: 'post',
    data: data
  })
}

// 删除用户注销申请
export function delCancel(data) {
  return request({
    url: '/user/cancel/delete',
    method: 'post',
    data: data
  })
}
